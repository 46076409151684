.root {
    border-top: 1px solid var(--border-line-color);
    transition: border-top-color .2s;
    padding: 0 1em 1.8em;
    margin-top: calc(7vh + 2vw + 2em);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.root > div {
    display: flex;
    width: 100%;
    margin-top: .6em;
}

.root div div {
    flex: auto;
    display: flex;
    flex-direction: column;
}
.root div div:last-child {
    text-align: right;
}
.root div div p {
    margin: .5em 0;
}

.projectName {
    font-weight: bold;
    color: var(--spotly-color);
}

.themeToggler {
    margin-top: -1em;
    margin-right: -1em;
    padding: .4em .6em .4em .3em;
    text-align: center;
    background: var(--bg-color);
    transition: background-color .2s;
    outline: none;
    border: none;
    font: inherit;
    cursor: pointer;
    position: relative;
    align-self: flex-end;
}
.themeToggler svg {
    width: 1.2em;
}
.themeToggler svg path:last-child {
    fill: var(--text-color);
    opacity: .8;
    transition: opacity .1s;
}
.themeToggler:hover svg path:last-child {
    opacity: 1;
}

.hiddenLogout {
    visibility: hidden;
}

@media all and (max-width: 500px) {
    .root > div {
        flex-direction: column;
    }
    .root div div:last-child {
        text-align: left;
    }
    /* Display logout button as last item on mobile to reach it easily */
    .logout {
        order: 1;
    }
    .hiddenLogout {
        display: none;
    }
}
