.root {
    padding-bottom: calc(3vh + 1em);
    flex-grow: 1;
}

.lyrics p {
    margin: 1.2em 0;
}
.lyrics p:first-child {
    margin-top: 1em;
}

p.geniusLink {
    margin-top: 4em;
    margin-bottom: -1em;
    display: flex;
}
.geniusLink a {
    margin-left: auto;
    padding: 1em 1em 1em 4em;
    display: flex;
    align-items: center;
    outline: none!important;
    text-decoration: none!important;
    color: var(--faded-link-color);
    font-weight: 600;
    transition: color .1s;
}
.geniusLink a:hover {
    color: var(--faded-link-hover-color);
}
.geniusLink img {
    margin-left: .4em;
    max-width: 1.6em;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.4));
    transition: filter .1s;
}
.geniusLink a:hover img {
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.8));
}
.geniusLink a:active, .geniusLink a:focus {
    opacity: 0.7;
}

.geniusIndicator {
    opacity: 0.9;
}
