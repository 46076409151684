.root {
    margin-top: var(--header-min-height);
    flex-grow: 1;
}

.buttonContainer {
    display: flex;
    justify-content: center;
}

.button {
    margin-top: 10vh;
    text-align: center;
    background: var(--spotify-green);
    padding: .7em 1.5em .7em 1.4em;
    text-decoration: none!important;
    color: white;
    display: flex;
    text-transform: uppercase;
    border-radius: 1.8em;
    font-weight: 600;
    transition: opacity .07s;
    align-items: center;
}

.button:hover {
    opacity: .9;
}
.button:focus, .button:active {
    color: rgba(255, 255, 255, 0.8);
}

.button img {
    width: 1.4em;
    height: 1.4em;
}

.button span {
    padding-left: .7em;
    align-self: center;
}

.imagePreload {
    opacity: 0.00001;
    max-width: 1em;
}
